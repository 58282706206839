import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import styles from './PartnersBanner.module.sass';
import { useEmblaSlider } from '../../../hooks/useEmblaSlider';
import { Dots } from '../../Dots';
// import { desktopBanners, mobBanners } from './arraysPartnersBanners';
import {
  NextArrow,
  PrevArrow,
} from '../../GalleryModal/components/Arrows/Arrows';
import { useSearchStore } from '../../../providers/SearchStoreProvider';
import { SkeletonCover } from '../../Skeleton/SkeletonCover';
import { Image } from '../../Image/Image';

export const PartnersBanners = observer(
  ({ isMobile, isTablet, isLandingPage, banners }) => {
    const { mainResult } = useSearchStore();
    const firstLoading = mainResult.isFirstLoading;

    // const partnerBanner = isMobile ? mobBanners : desktopBanners;
    const partnerBanner = banners;
    const ReworkImageUrl = url => {
      if (isMobile) {
        return `https://kruiz.online/images/main_page/banners/mobile/${url}`;
      }
      if (isTablet) {
        return `https://kruiz.online/images/main_page/banners/desktop/${url}`;
      }
      return `https://kruiz.online/images/main_page/banners/desktop/${url}`;
    };

    const {
      ref,
      current,
      onSelectSlide,
      scrollPrev,
      scrollNext,
      prevBtnEnabled,
      nextBtnEnabled,
    } = useEmblaSlider({ loop: true }, false);

    useEffect(() => {
      const interval = setInterval(() => {
        scrollNext();
      }, 4000); // Меняем слайд каждые 4 секунды

      return () => clearInterval(interval);
    }, [scrollNext]);
    return (
      <section
        className={isMobile ? styles.partnerBannerMob : styles.partnersBanner}
      >
        {firstLoading && !isLandingPage ? (
          <SkeletonCover width={1100} height={220} />
        ) : (
          <div className={styles.embla} ref={ref}>
            <div className={styles.emblaContainer}>
              {partnerBanner?.map((i, key) => (
                <div key={key} className={styles.emblaSlide}>
                  <a href={i?.page_url} z key={key}>
                    <Image
                      src={ReworkImageUrl(i.src)}
                      srcSet={ReworkImageUrl(i.srcset)}
                    />
                    {i?.imgBanner}
                  </a>
                </div>
              ))}
            </div>
            <PrevArrow onClick={scrollPrev} enabled={prevBtnEnabled} />
            <NextArrow onClick={scrollNext} enabled={nextBtnEnabled} />
            <Dots
              view="longDots"
              spaceBetween={6}
              count={partnerBanner?.length}
              topGap={12}
              active={current}
              onSelect={onSelectSlide}
            />
          </div>
        )}
      </section>
    );
  },
);
